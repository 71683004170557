/* autoprefixer grid: autoplace */
/* main: '#403FCE',
light: '#6977F5',
dark: '#99fcc1', */

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.7em"]::before {
  content: 'Petit';
  font-size: 0.7em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
  content: 'Normal';
  font-size: 1em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
  content: 'Large';
  font-size: 1.5em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2em"]::before {
  content: 'Huge';
  font-size: 2em !important;
}

.pathPlanClicked path {
  -webkit-transform: scale(3) !important;
  -webkit-transform-origin: 50% 100%;
  -webkit-transition: 0.3s;
  transform: scale(3);
  transform-origin: 50% 100%;
  transition: 0.3s;
}

.space path {
  -webkit-transform: scale(1);
  -webkit-transform-origin: 50% 100%;
  -webkit-transition: 0.3s;
  transform: scale(1);
  transform-origin: 50% 100%;
  transition: 0.3s;
  transform-box: fill-box;
}

.slick-dots .slick-active div {
  background-color: #605ecf;
}
.introjs-tooltipbuttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.iframe-container {
  width: 40px;
}

iframe {
  overflow: hidden;
}

.ql-editor {
  max-height: 400px;
}
.quill-blur {
  display: none;
}

.ql-editor {
  -webkit-user-select: text !important; /* Safari */
  -moz-user-select: text !important; /* Firefox */
  -ms-user-select: text !important; /* IE10+/Edge */
  user-select: text !important; /* Standard */
}

.ql-toolbar.ql-snow {
  border: none;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 4;
}

.ql-picker-options {
  height: 40vh;
  overflow: scroll;
}
/* START FONT*/
.ql-font-abrilfatface {
  font-family: "Abril Fatface", cursive;
}
.ql-picker-item[data-value="abrilfatface"]::before {
  font-family: "Abril Fatface", cursive;
}

.ql-font-anticslab {
  font-family: "Antic Slab", serif;
}
.ql-picker-item[data-value="anticslab"]::before {
  font-family: "Antic Slab", serif;
}

.ql-font-anton {
  font-family: "Anton", sans-serif;
}
.ql-picker-item[data-value="anton"]::before {
  font-family: "Anton", sans-serif;
}

.ql-font-arial {
  font-family: "Arial";
}
.ql-picker-item[data-value="arial"]::before {
  font-family: "Arial";
}

.ql-font-arimo {
  font-family: "Arimo", sans-serif;
}
.ql-picker-item[data-value="arimo"]::before {
  font-family: "Arimo", sans-serif;
}

.ql-container {
  font-size: 1.3vw!important;
}

.ql-font-arvo {
  font-family: "Arvo", sans-serif;
}
.ql-picker-item[data-value="arvo"]::before {
  font-family: "Arvo", sans-serif;
}

.ql-font-courgette {
  font-family: "Courgette", cursive;
}
.ql-picker-item[data-value="courgette"]::before {
  font-family: "Courgette", cursive;
}

.ql-font-dosis {
  font-family: "Dosis", sans-serif;
}
.ql-picker-item[data-value="dosis"]::before {
  font-family: "Dosis", sans-serif;
}

.ql-font-expletussans {
  font-family: "Expletus Sans", cursive;
}
.ql-picker-item[data-value="expletussans"]::before {
  font-family: "Expletus Sans", cursive;
}

.ql-font-karla {
  font-family: "Karla", sans-serif;
}
.ql-picker-item[data-value="karla"]::before {
  font-family: "Karla", sans-serif;
}

.ql-font-inconsolata {
  font-family: "Inconsolata", sans-serif;
}
.ql-picker-item[data-value="inconsolata"]::before {
  font-family: "Inconsolata", sans-serif;
}

.ql-font-josefinsans {
  font-family: "Josefin Sans", sans-serif;
}
.ql-picker-item[data-value="josefinsans"]::before {
  font-family: "Josefin Sans", sans-serif;
}

.ql-font-lato {
  font-family: "Lato", sans-serif;
}
.ql-picker-item[data-value="lato"]::before {
  font-family: "Lato", sans-serif;
}

.ql-font-lobster {
  font-family: "Lobster", sans-serif;
}
.ql-picker-item[data-value="lobster"]::before {
  font-family: "Lobster", sans-serif;
}

.ql-font-montserrat {
  font-family: "Montserrat", sans-serif;
}
.ql-picker-item[data-value="montserrat"]::before {
  font-family: "Montserrat", sans-serif;
}

.ql-font-novaround {
  font-family: "Nova Round", cursive;
}
.ql-picker-item[data-value="novaround"]::before {
  font-family: "Nova Round", cursive;
}

.ql-font-oswald {
  font-family: "Oswald", sans-serif;
}
.ql-picker-item[data-value="oswald"]::before {
  font-family: "Oswald", sans-serif;
}

.ql-font-pacifico {
  font-family: "Pacifico", cursive;
}

.ql-picker-item[data-value="pacifico"]::before {
  font-family: "Pacifico", cursive;
}

.ql-font-palanquin {
  font-family: "Palanquin", sans-serif;
}

.ql-picker-item[data-value="pacifico"]::before {
  font-family: "Palanquin", sans-serif;
}

.ql-font-play {
  font-family: "Play", sans-serif;
}

.ql-picker-item[data-value="play"]::before {
  font-family: "Play", sans-serif;
}

.ql-font-poppins {
  font-family: "Poppins", sans-serif;
}
.ql-picker-item[data-value="poppins"]::before {
  font-family: "Poppins", sans-serif;
}

.ql-font-quicksand {
  font-family: "Quicksand", sans-serif;
}
.ql-picker-item[data-value="quicksand"]::before {
  font-family: "Quicksand", sans-serif;
}

.ql-font-raleway {
  font-family: "Raleway", sans-serif;
}
.ql-picker-item[data-value="raleway"]::before {
  font-family: "Raleway", sans-serif;
}

.ql-font-roboto {
  font-family: "Roboto", sans-serif;
}
.ql-picker-item[data-value="roboto"]::before {
  font-family: "Roboto", sans-serif;
}

.ql-font-robotoslab {
  font-family: "Roboto Slab", serif;
}
.ql-picker-item[data-value="robotoslab"]::before {
  font-family: "Roboto Slab", serif;
}

.ql-font-shadowsintolight {
  font-family: "Shadows Into Light", cursive;
}
.ql-picker-item[data-value="shadowsintolight"]::before {
  font-family: "Shadows Into Light", cursive;
}

.ql-font-sora {
  font-family: "Sora", sans-serif;
}
.ql-picker-item[data-value="sora"]::before {
  font-family: "Sora", sans-serif;
}

.ql-font-spartan {
  font-family: "Spartan", sans-serif;
}
.ql-picker-item[data-value="spartan"]::before {
  font-family: "Spartan", sans-serif;
}

.ql-font-sourcesanspro {
  font-family: "Source Sans Pro", sans-serif;
}
.ql-picker-item[data-value="sourcesanspro"]::before {
  font-family: "Source Sans Pro", sans-serif;
}

.ql-font-titilliumweb {
  font-family: "Titillium Web", sans-serif;
}
.ql-picker-item[data-value="titilliumweb"]::before {
  font-family: "Titillium Web", sans-serif;
}

.ql-font-yanonekaffeesatz {
  font-family: "Yanone Kaffeesatz", sans-serif;
}
.ql-picker-item[data-value="yanonekaffeesatz"]::before {
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.ql-font-yatraone {
  font-family: "Yatra One", cursive;
}
.ql-picker-item[data-value="yatraone"]::before {
  font-family: "Yatra One", cursive;
}

.ql-font-yeonsung {
  font-family: "Yeon Sung", cursive;
}
.ql-picker-item[data-value="yeonsung"]::before {
  font-family: "Yeon Sung", cursive;
}

/* END FONT*/

.first-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.introjs-skipbutton {
  margin-right: 0 !important;
}

.introjs-button {
  background-color: #6977f5 !important;
  background-image: none;
  color: #fff !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  text-transform: none !important;
  padding: 4px 15px !important;
  border: none;
  text-shadow: none;
  border-radius: 30px;
}

.introjs-button:focus,
.introjs-button:active {
  background-image: none;
}

.introjs-tooltiptext {
  color: #3d4244;
  font-weight: 400;
}

.introjs-disabled {
  background-color: #cecece !important;
  color: #fff !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.introjs-button:hover {
  background-color: #3332a4 !important;
}

.introjs-disabled:hover {
  background-color: #cecece !important;
}

.introjs-tooltip {
  border-radius: 10px !important;
  padding: 20px !important;
  min-width: 650px !important;
}

.introjs-overlay {
  background: #3d4244 !important;
}

.introjs-helperNumberLayer {
  opacity: 0 !important;
}

.introjs-helperLayer {
  box-shadow: none !important;
  border: none;
}

body {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.sortableHelper {
  z-index: 99999999999;
}

.sortableOverlay {
  /* transform: scale(0.5) */
  height: 70px;
}

.activeMenuItem a {
  background: red;
}

.activeMenuItem {
  background: red;
}

.makeStyles-main-1 {
  justify-content: center !important;
}

.App-intro {
  font-size: large;
}

.datePickerTime > .react-datepicker__time-container {
  right: -45% !important;
  top: 5% !important;
}

.submenuText {
  color: #5bfcc2 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.active .componentCard {
  border: 2px solid #403fce;
}
.componentCard {
  border: none;
}
